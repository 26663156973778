import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const config = {
  apiKey: "AIzaSyBa_wv9ESGZYxd1yNriDlaAeLM6vUzuIxo",
  authDomain: "plc-simulator-556f9.firebaseapp.com",
  projectId: "plc-simulator-556f9",
  storageBucket: "plc-simulator-556f9.firebasestorage.app",
  messagingSenderId: "950069321859",
  appId: "1:950069321859:web:a731d735b635acf6dffbb6",
};

firebase.initializeApp(config);


export const auth = firebase.auth();

export default firebase;
